.framePage-title {
  position: relative;
}
.framePage-title:after {
  position: absolute;
  background-color: #2d57f6;
  width: 4px;
  height: 20px;
  top: 25%;
  left: 0;
  content: "";
}
.tittleIndex2::after {
  position: absolute;
  background-color: #2d57f6;
  width: 4px;
  height: 20px;
  left: 0;
  content: "";
  top: 24px;
}
.framePage-body {
  background: #f7fbfe;
}
.tittleIndex2 {
  font-size: 20px;
  padding: 20px;
  font-weight: bold;
}
.l .guideBox {
  min-height: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(40, 120, 255, 0.1);
  padding: 0 30px;
  border-radius: 9px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.l .l_l {
  display: flex;
  height: 30%;
  border-radius: 12px;
  padding: 20px 25px;
  width: 48%;
}
.l .l_l:hover {
  box-shadow: 0px 0px 20px 0px rgba(40, 120, 255, 0.1);
}
.l .l_l .l_1 {
  display: flex;
  flex-direction: column;
}
.l .l_l .l_1 .stepBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
}
.l .l_l .l_1 .flexExamples {
  display: flex;
  align-items: center;
}
.l .l_l .l_1 .flexExamples span {
  color: #F3C97A ;
  border-bottom: 1px solid #F3C97A;
  padding: 3px 0;
}
.l .l_l .l_1 .p_1 {
  color: #5393ff;
}
.l .l_l .l_1 .p_2 {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 600;
  position: relative;
}
.l .l_l .l_1 .p_2 img {
  position: absolute;
  left: -28px;
  top: 8px;
}
.l .l_l .l_1 .dh:hover {
  color: #2878ff;
  cursor: pointer;
}
.l .l_l .l_1 .p_3 {
  font-size: 15px;
  margin-top: 5px;
}
.l .bgImg1 {
  background-image: url("../../assets/JgIndex/JgIndex1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.l .bgImg2 {
  background-image: url("../../assets/JgIndex/JgIndex2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.l .bgImg3 {
  background-image: url("../../assets/JgIndex/JgIndex3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.l .bgImg4 {
  background-image: url("../../assets/JgIndex/JgIndex4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.l .bgImg5 {
  background-image: url("../../assets/JgIndex/JgIndex5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.l .bgImg6 {
  background-image: url("../../assets/JgIndex/JgIndex6.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.r .noticeBox {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 38px 0px rgba(40, 120, 255, 0.1);
  border-radius: 9px;
  position: relative;
}
.r .noticeBox .noticeContent {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.r .noticeBox .noticeContent:hover {
  color: #2878ff;
  cursor: pointer;
}
.el-row {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  height: 100%;
  justify-content: space-around;
}
